<template>
  <div>
    <div class="mb-3 flex justify-between">
      <router-link to="/admin/sysadmin/employers">
        <div class="header-text hover:cursor-pointer">
          <font-awesome-icon icon="chevron-circle-left" aria-label="back" />
          {{ employer.employer_name }}
        </div>
      </router-link>

      <Button
        @click="
          $router.push(`/admin/sysadmin/employers/edit/${employer.employer_id}`)
        "
        text="Edit"
        class="font-semibold"
      />
    </div>
    <div class="box-style overflow-auto">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div class="secondhead-text">Employer Details</div>
        <div class="font-semibold mb-2">Number of Employees</div>
        <div class="mb-3 ml-3">{{ employer.number_employees }}</div>
        <div class="font-semibold mb-2">Website</div>
        <div class="mb-3 ml-3">{{ employer.web_site }}</div>
        <div class="secondhead-text">Employer Contact</div>
        <div class="font-semibold mb-2">Phone</div>
        <div class="mb-3 ml-3">{{ employer.phone.phone_number }}</div>
        <div class="font-semibold mb-2">Address</div>
        <div class="mb-3 ml-3">
          <div>{{ employer.address.address_line_one }}</div>
          <div>{{ employer.address.address_line_two }}</div>
          <div>
            {{ employer.address.city }} {{ employer.address.state }},
            {{ employer.address.zip_code }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import { get } from "@/composables/httpUtil";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "ViewEmployer",
  components: { Button, LoadingSpinner },
  data() {
    return {
      employer: {},
      isLoading: false,
    };
  },
  created() {
    const url = `admin/employers/${this.$route.params.id}/`;
    this.isLoading = true;
    get(url).then(
      (response) => {
        this.employer = response;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  },
};
</script>

<style scoped></style>
